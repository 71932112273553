import logo from './expbearhalf.png';
import CountdownTimer from './CountdownTimer'
import './App.css';



function App() {
  //const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;
  //const NOW_IN_MS = new Date().getTime();

  //const TARGET_RELEASE_DATE = Date.now('June 12 12:00:00');

  const handleSubmit = async () => {
    const email = document.getElementById('email').value;

    try {
      const response = await fetch('submit_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `email=${encodeURIComponent(email)}`,
      });

      if (response.ok) {
        console.log('Email submitted successfully');
        
        // Show confirmation message
        alert('Thank you for registering!');

      } else {
        console.error('Failed to submit email');
        // Handle error case, such as displaying an error message to the user.
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      // Handle network errors or other exceptions.
    }
  };

  return (
    <div className="landingContainer">
      <div> 
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App">
          <header>
            <h1>Let's begin your path to success!</h1>
            <h3>Register to know when we're ready!</h3>
            <input placeholder="enter your email here..." type="email" id="email" name="email" />
            <button className="registerButton" onClick={handleSubmit}>Register Now</button>
            {/* <CountdownTimer targetDate={TARGET_RELEASE_DATE} /> */}
          </header>
        </div>
      </div>
    </div>
  );
}

export default App;
